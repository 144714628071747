import React from 'react';
import { Link } from 'react-router-dom';
import { ExerciseUpd } from './ExerciseUpd';

interface ExercisesGridProps {
  exercises: ExerciseUpd[];
  pageIndex: number;
  hasMore: boolean;
  onPageChange: (direction: 'next' | 'prev') => void;
}

export const ExercisesGrid: React.FC<ExercisesGridProps> = ({
  exercises,
  pageIndex,
  hasMore,
  onPageChange
}) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
        {exercises.map((exercise) => (
          <Link
            key={exercise.id}
            to={`/exercises/${exercise.id}`}
            className="block bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="relative h-16 sm:h-20">
              <img
              loading='lazy'
                src={exercise.thumbnail_URL}
                alt={exercise.translations?.title}
                className="w-full h-full object-contain rounded-t-lg"
              />
            </div>
            <div className="p-4">
              <h3 className="text-sm font-semibold mb-2">
                {exercise.translations?.title}
              </h3>
             
            </div>
          </Link>
        ))}
      </div>
      
      <div className="flex justify-center space-x-4 mt-8">
        <button
          onClick={() => onPageChange('prev')}
          disabled={pageIndex === 0}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
             {'<'}
        </button>
        <span className="px-4 py-2">Page {pageIndex + 1}</span>
        <button
          onClick={() => onPageChange('next')}
          disabled={!hasMore}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          {'>'}
        </button>
      </div>
    </div>
  );
};
