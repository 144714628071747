import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { DocumentData } from 'firebase/firestore';
import { PlanUpd } from '../components/PlanUpd';
import { WorkoutUpd } from '../components/WorkoutUpd';
import { ExerciseUpd } from '../components/ExerciseUpd';
import { fetchPlansUpd } from '../service/fetchPlans';
import { fetchPaginatedWorkouts } from '../service/fetchWorkouts';
import { fetchPaginatedExercises } from '../service/fetchExercise';
import { PlansGrid } from '../components/renderPlans';
import { WorkoutsGrid } from '../components/renderWorkouts';
import { ExercisesGrid } from '../components/renderExercises';
import Loading from '../components/loading';

const Home: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  // Get initial tab from query param or default to 'plans'
  const initialTab = searchParams.get('tab') as 'plans' | 'workouts' | 'exercises' | null;
  const [selectedTab, setSelectedTab] = useState<'plans' | 'workouts' | 'exercises'>(
    initialTab && ['plans', 'workouts', 'exercises'].includes(initialTab) ? initialTab : 'plans'
  );
  
  // Plans state
  const [plans, setPlans] = useState<PlanUpd[]>([]);
  const [plansLoading, setPlansLoading] = useState(false);
  const [plansError, setPlansError] = useState<string | null>(null);

  // Workouts state
  const [workouts, setWorkouts] = useState<WorkoutUpd[]>([]);
  const [workoutsLoading, setWorkoutsLoading] = useState(false);
  const [workoutsError, setWorkoutsError] = useState<string | null>(null);
  const [workoutsLastDoc, setWorkoutsLastDoc] = useState<DocumentData | null>(null);
  const [workoutsHasMore, setWorkoutsHasMore] = useState(true);
  const [workoutsPageIndex, setWorkoutsPageIndex] = useState(0);

  const [exercises, setExercises] = useState<ExerciseUpd[]>([]);
  const [exercisesLoading, setExercisesLoading] = useState(false);
  const [exercisesError, setExercisesError] = useState<string | null>(null);
  const [lastDoc, setLastDoc] = useState<DocumentData | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const ITEMS_PER_PAGE = 15;

  const [searchString, setSearchString] = useState('');
  const [selectedBodyParts, setSelectedBodyParts] = useState<string[]>([]);
  const bodyPartOptions = ['Abs', 'Biceps', 'Calves', 'Chest', 'External Oblique', 'Forearms', 'Glutes', 'Neck', 'Quads', 'Shoulders', 'Triceps', 'Hamstrings', 'Lats', 'Lower Back', 'Traps', 'Full body'];
  const [isFilterCollapsed, setIsFilterCollapsed] = useState(true);

  const handleSearch = (value: string) => {
    setSearchString(value);
    if (selectedTab === 'workouts') {
      fetchWorkouts('start', selectedBodyParts, value);
    } else if (selectedTab === 'exercises') {
      fetchExercises('start', selectedBodyParts, value);
    }
  };

  const handleBodyPartFilter = (bodyPart: string) => {
    const newBodyParts = selectedBodyParts.includes(bodyPart)
      ? selectedBodyParts.filter(bp => bp !== bodyPart)
      : [...selectedBodyParts, bodyPart];
    
    setSelectedBodyParts(newBodyParts);
    if (selectedTab === 'workouts') {
      fetchWorkouts('start', newBodyParts, searchString);
    } else if (selectedTab === 'exercises') {
      fetchExercises('start', newBodyParts, searchString);
    }
  };

  // Fetch Plans
  useEffect(() => {
    const fetchPlans = async () => {
      setPlansLoading(true);
      try {
        const plansData = await fetchPlansUpd();
        setPlans(plansData.filter((plan) => plan.created_by === 'ht0J8E9RuxS1zT8UKd2cORf5Ea72'));
      } catch (err) {
        setPlansError('Failed to load plans.');
      } finally {
        setPlansLoading(false);
      }
    };

    if (selectedTab === 'plans' && plans.length === 0 && !plansLoading) {
      fetchPlans();
    } else if (selectedTab === 'workouts' && workouts.length === 0 && !workoutsLoading) {
      fetchWorkouts('start');
    } else if (selectedTab === 'exercises' && exercises.length === 0 && !exercisesLoading) {
      fetchExercises('start');
    }
  }, [selectedTab]);

  const fetchWorkouts = async (direction: 'next' | 'prev' | 'start', bodyParts?: string[], search?: string) => {
    setWorkoutsLoading(true);
    try {
      if (direction === 'start') {
        setWorkoutsPageIndex(0);
        setWorkoutsLastDoc(null);
      }

      if (direction === 'prev' && workoutsPageIndex === 0) {
        return;
      }

      const response = await fetchPaginatedWorkouts(
        direction === 'next' ? workoutsLastDoc : null,
        ITEMS_PER_PAGE,
        bodyParts,
        search
      );

      setWorkouts(response.workouts);
      setWorkoutsLastDoc(response.lastDoc);
      setWorkoutsHasMore(response.hasMore);

      if (direction === 'next') {
        setWorkoutsPageIndex(prev => prev + 1);
      } else if (direction === 'prev') {
        setWorkoutsPageIndex(prev => Math.max(0, prev - 1));
      }

    } catch (err) {
      setWorkoutsError('Failed to load workouts.');
    } finally {
      setWorkoutsLoading(false);
    }
  };

  const fetchExercises = async (direction: 'next' | 'prev' | 'start', bodyParts?: string[], search?: string) => {
    setExercisesLoading(true);
    try {
      if (direction === 'start') {
        setPageIndex(0);
        setLastDoc(null);
      }

      if (direction === 'prev' && pageIndex === 0) {
        return;
      }

      const response = await fetchPaginatedExercises(
        direction === 'next' ? lastDoc : null,
        ITEMS_PER_PAGE,
        bodyParts,
        search
      );

      setExercises(response.exercises);
      setLastDoc(response.lastDoc);
      setHasMore(response.hasMore);

      if (direction === 'next') {
        setPageIndex(prev => prev + 1);
      } else if (direction === 'prev') {
        setPageIndex(prev => Math.max(0, prev - 1));
      }

    } catch (err) {
      setExercisesError('Failed to load exercises.');
    } finally {
      setExercisesLoading(false);
    }
  };

  // Update URL when tab changes
  const handleTabChange = (tab: typeof selectedTab) => {
    setSelectedTab(tab);
    navigate(`?tab=${tab}`, { replace: true });
  };

  return (
    <div className="container mx-auto px-4 py-4 relative">
      <header className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        <a
          href="https://kinestex.com"
          target="_blank"
          rel="noopener noreferrer"
          className="order-1 md:order-2 text-xl font-bold text-gray-800 hover:text-green-600"
        >
          <span>KinesteX AI Content Library</span>
        </a>
        
        <div className="order-2 md:order-1 inline-flex rounded-lg">
          {['plans', 'workouts', 'exercises'].map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabChange(tab as typeof selectedTab)}
              className={`px-4 py-2 rounded-lg ${
                selectedTab === tab
                  ? 'bg-blue-500 text-white'
                  : 'bg-white text-blue-500'
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
      </header>

      <div className="flex flex-col md:flex-row gap-6">
        {(selectedTab === 'workouts' || selectedTab === 'exercises') && (
          <div className={`
            md:w-64 flex-shrink-0
            ${selectedTab !== 'workouts' && selectedTab !== 'exercises' ? 'hidden' : ''}
          `}>
            <div className="space-y-4 md:sticky md:top-4">
              <input
                type="text"
                value={searchString}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search..."
                className="w-full p-2 border rounded"
              />
              
              <div className="md:hidden">
                <button
                  onClick={() => setIsFilterCollapsed(!isFilterCollapsed)}
                  className="w-full p-2 bg-gray-100 rounded flex justify-between items-center"
                >
                  <span>Filter by body parts</span>
                  <span className="transform transition-transform duration-200 inline-block">
                    {isFilterCollapsed ? '▼' : '▲'}
                  </span>
                </button>
              </div>

              <div className={`
                flex flex-wrap md:flex-col gap-2
                ${isFilterCollapsed ? 'hidden md:flex' : 'flex'}
              `}>
                {bodyPartOptions.map(bodyPart => (
                  <button
                    key={bodyPart}
                    onClick={() => handleBodyPartFilter(bodyPart)}
                    className={`px-3 py-1 rounded w-full text-left ${
                      selectedBodyParts.includes(bodyPart)
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200'
                    }`}
                  >
                    {bodyPart}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className={`flex-1`}>
          {selectedTab === 'plans' && (
            plansLoading ? (
              <Loading />
            ) : plansError ? (
              <div>{plansError}</div>
            ) : (
              <PlansGrid plans={plans} />
            )
          )}

          {selectedTab === 'workouts' && (
            workoutsLoading ? (
              <Loading />
            ) : workoutsError ? (
              <div>{workoutsError}</div>
            ) : (
              <WorkoutsGrid 
                workouts={workouts}
                pageIndex={workoutsPageIndex}
                hasMore={workoutsHasMore}
                onPageChange={(direction) => fetchWorkouts(direction)}
              />
            )
          )}

          {selectedTab === 'exercises' && (
            exercisesLoading ? (
              <Loading />
            ) : exercisesError ? (
              <div>{exercisesError}</div>
            ) : (
              <ExercisesGrid 
                exercises={exercises}
                pageIndex={pageIndex}
                hasMore={hasMore}
                onPageChange={(direction) => fetchExercises(direction)}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;