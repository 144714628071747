import React from 'react';
import { Link } from 'react-router-dom';
import { WorkoutUpd } from './WorkoutUpd';

interface WorkoutsGridProps {
  workouts: WorkoutUpd[];
  pageIndex: number;
  hasMore: boolean;
  onPageChange: (direction: 'next' | 'prev') => void;
}

export const WorkoutsGrid: React.FC<WorkoutsGridProps> = ({ 
  workouts, 
  pageIndex, 
  hasMore, 
  onPageChange 
}) => {
  return (
    <div className="flex flex-col space-y-6">
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 sm:gap-4">
        {workouts.map((workout) => (
          <Link
            key={workout.id}
            to={`/workouts/${workout.id}`}
            className="block bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <img
              loading='lazy'
              src={workout.workout_desc_img}
              alt={workout.translations?.title}
              className="w-full h-24 sm:h-32 object-cover rounded-t-lg"
            />
            <div className="p-3">
              <h3 className="text-sm mb-2 font-semibold">{workout.translations?.title}</h3>
            </div>
          </Link>
        ))}
      </div>
      
      <div className="flex justify-center space-x-4">
        <button
          onClick={() => onPageChange('prev')}
          disabled={pageIndex === 0}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <span className="px-4 py-2">Page {pageIndex + 1}</span>
        <button
          onClick={() => onPageChange('next')}
          disabled={!hasMore}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};
