// src/service/fetchWorkouts.ts
import { db } from '../components/firebase';
import { collection, getDocs, getDoc, doc, query, where, DocumentData, Query, orderBy, startAfter, limit } from 'firebase/firestore';
import { WorkoutUpd, enWorkoutTranslation } from '../components/WorkoutUpd';
import { normalizeString } from '../utils/stringUtils';

// In fetchWorkouts.ts, add interface and modify fetchWorkoutsUpd:

interface FetchWorkoutsResponse {
    workouts: WorkoutUpd[];
    lastDoc: DocumentData | null;
    hasMore: boolean;
  }
  
  export const fetchPaginatedWorkouts = async (
    lastDoc: DocumentData | null = null,
    pageSize: number = 9,
    bodyParts?: string[],
    searchString?: string,
    language: string = 'en'
  ): Promise<FetchWorkoutsResponse> => {
    try {
      const workoutsRef = collection(db, 'workouts_upd');
      let q: Query = query(
        workoutsRef,
        where('created_by', '==', 'ht0J8E9RuxS1zT8UKd2cORf5Ea72')
      );
  
      if (bodyParts && bodyParts.length > 0) {
        q = query(
          q,
          where(
            `filter_fields.${language}.body_parts`,
            "array-contains-any",
            bodyParts
          )
        );
      }
  
      if (searchString) {
        const normalizedSearchString = normalizeString(searchString);
        q = query(
          q,
          where(
            `filter_fields.${language}.normalized_title`,
            ">=",
            normalizedSearchString
          ),
          where(
            `filter_fields.${language}.normalized_title`,
            "<=",
            normalizedSearchString + "\uf8ff"
          )
        );
      }
  
      if (lastDoc) {
        q = query(q, startAfter(lastDoc), limit(pageSize));
      } else {
        q = query(q, limit(pageSize));
      }
  
      const querySnapshot = await getDocs(q);
      
      const workoutsData = await Promise.all(querySnapshot.docs.map(async (workoutDoc) => {
        const workoutData = workoutDoc.data();
        
        const translationsRef = doc(workoutDoc.ref, 'translations', 'en');
        const translationsSnap = await getDoc(translationsRef);
        
        const translations = translationsSnap.exists() ? 
          translationsSnap.data() as enWorkoutTranslation : 
          null;
  
        return {
          id: workoutDoc.id,
          workout_desc_img: workoutData.workout_desc_img,
          total_minutes: workoutData.total_minutes,
          calories: workoutData.calories,
          sequence: workoutData.sequence,
          filter_fields: workoutData.filter_fields,
          created_by: workoutData.created_by,
          translations: translations
        } as WorkoutUpd;
      }));
  
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      const hasMore = querySnapshot.docs.length === pageSize;
  
      return {
        workouts: workoutsData,
        lastDoc: lastVisible || null,
        hasMore
      };
    } catch (error) {
      console.error('Error fetching paginated workouts:', error);
      return {
        workouts: [],
        lastDoc: null,
        hasMore: false
      };
    }
  };
  
export const fetchWorkoutsUpd = async (): Promise<WorkoutUpd[]> => {
    const workoutsUpdCollection = collection(db, 'workouts_upd');
    const workoutsQuery = query(
      workoutsUpdCollection,
      where('created_by', '==', 'ht0J8E9RuxS1zT8UKd2cORf5Ea72')
    );
    const querySnapshot = await getDocs(workoutsQuery);
    
    const workoutsUpdData = await Promise.all(querySnapshot.docs.map(async (workoutDoc) => {
      const workoutData = workoutDoc.data();
      
      const translationsRef = doc(workoutDoc.ref, 'translations', 'en');
      const translationsSnap = await getDoc(translationsRef);
      
      const translations = translationsSnap.exists() ? 
        translationsSnap.data() as enWorkoutTranslation : 
        null;
  
      return {
        id: workoutDoc.id,
        workout_desc_img: workoutData.workout_desc_img,
        total_minutes: workoutData.total_minutes,
        calories: workoutData.calories,
        sequence: workoutData.sequence,
        filter_fields: workoutData.filter_fields,
        created_by: workoutData.created_by,
        translations: translations
      } as WorkoutUpd;
    }));
  
    return workoutsUpdData;
  };

export const fetchWorkoutById = async (workoutId: string): Promise<WorkoutUpd | null> => {
  try {
    const workoutRef = doc(db, 'workouts_upd', workoutId);
    const workoutSnap = await getDoc(workoutRef);

    if (!workoutSnap.exists()) {
      return null;
    }

    const workoutData = workoutSnap.data();

    const translationsRef = doc(workoutRef, 'translations', 'en');
    const translationsSnap = await getDoc(translationsRef);
    
    const translations = translationsSnap.exists() ? 
      translationsSnap.data() as enWorkoutTranslation : 
      null;

    return {
      id: workoutSnap.id,
      workout_desc_img: workoutData.workout_desc_img,
      total_minutes: workoutData.total_minutes,
      calories: workoutData.calories,
      sequence: workoutData.sequence,
      filter_fields: workoutData.filter_fields,
      created_by: workoutData.created_by,
      translations: translations
    } as WorkoutUpd;

  } catch (error) {
    console.error('Error fetching workout:', error);
    return null;
  }
};