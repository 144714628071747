import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { fetchWorkoutById } from "../service/fetchWorkouts";
import { WorkoutUpd } from "../components/WorkoutUpd";
import { FaClock, FaFire, FaDumbbell } from "react-icons/fa";
import { fetchExercisesByIds } from "../service/fetchExercise";
import { ExerciseUpd } from "../components/ExerciseUpd";
import Loading from "../components/loading";

const WorkoutDetails: React.FC = () => {
  const { workoutId } = useParams<{ workoutId: string }>();
  const [workout, setWorkout] = useState<WorkoutUpd | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [exercisesMap, setExercisesMap] = useState<{
    [key: string]: ExerciseUpd;
  }>({});

  useEffect(() => {
    const loadWorkout = async () => {
      setLoading(true);
      try {
        if (!workoutId) throw new Error("No workout ID provided");
        const workoutData = await fetchWorkoutById(workoutId);
        if (!workoutData) throw new Error("Workout not found");
        setWorkout(workoutData);
        await processSequence(workoutData);
      } catch (err) {
        console.error("Error fetching workout:", err);
        setError("Failed to load workout");
      } finally {
        setLoading(false);
      }
    };

    const processSequence = async (workoutData: WorkoutUpd) => {
      const sequenceItems = Object.entries(workoutData.sequence)
        .sort(([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB))
        .map(([, value]) => value);

      // Get unique exercise titles (excluding rest)
      const uniqueExerciseTitles = Array.from(
        new Set(sequenceItems.filter((item) => item.id).map((item) => item.id))
      );

      console.log("Unique exercise titles:", uniqueExerciseTitles);
      // Fetch all exercises in bulk
      const exercises = await fetchExercisesByIds(uniqueExerciseTitles);

      // Convert array to map for easier lookup
      const exercisesMapTemp: { [key: string]: ExerciseUpd } = {};
      exercises.forEach((exercise) => {
        if (exercise && exercise.translations) {
          exercisesMapTemp[exercise.id] = exercise;
        }
      });

      setExercisesMap(exercisesMapTemp);
    };

    loadWorkout();
  }, [workoutId]);

  if (loading) {
   return (<Loading />);
  }

  if (error || !workout) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h2 className="text-xl text-red-500">
          {error || "Workout not found."}
        </h2>
      </div>
    );
  }

  const sequenceItems = Object.entries(workout.sequence)
    .sort(([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB))
    .map(([, value]) => value);

  return (
    <div className="container mx-auto p-4">
      {/* Workout Title */}
      <h1 className="text-4xl font-bold mb-4 text-center">
        {workout.translations?.title}
      </h1>

      <p className="text-gray-600 mb-2 text-center">ID: {workout.id}</p>
      {/* Workout Image */}
      {workout.workout_desc_img && (
        <div className="flex justify-center mb-6">
          <img
            src={workout.workout_desc_img}
            alt={workout.translations?.title}
            className="w-full max-w-2xl h-auto rounded-lg shadow-md"
          />
        </div>
      )}

      {/* Workout Details */}
      <div className="mb-8 flex flex-col items-center">
        {/* Body Parts */}
        {workout.translations?.body_parts && (
          <div className="flex flex-wrap justify-center mb-4">
            {workout.translations.body_parts.map((part, index) => (
              <span
                key={index}
                className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 mb-2 px-3 py-1 rounded-full"
              >
                {part}
              </span>
            ))}
          </div>
        )}

        {/* Other Details */}
        <div className="flex flex-wrap justify-center space-x-6 text-gray-600">
          <p className="flex items-center">
            <FaClock className="inline-block mr-2" />
            Duration: {workout.total_minutes || "N/A"} minutes
          </p>
          <p className="flex items-center">
            <FaFire className="inline-block mr-2" />
            Calories: {workout.calories || "N/A"}
          </p>
          <p className="flex items-center">
            <FaDumbbell className="inline-block mr-2" />
            Difficulty: {workout.translations?.dif_level || "N/A"}
          </p>
        </div>
      </div>

      {/* Workout Description */}
      {workout.translations?.description && (
        <p className="text-lg text-gray-700 mb-8 max-w-3xl mx-auto">
          {workout.translations.description}
        </p>
      )}

      {/* Sequence of Exercises */}
      <h2 className="text-3xl font-semibold mb-6 text-center">
        Order of exercises
      </h2>
      <div className="space-y-4 max-w-3xl mx-auto">
        {sequenceItems.map((item, index) => {
          const isRest = item.title?.toLowerCase() === "rest";
          const repeats = item.repeats ?? exercisesMap[item.id]?.repeats;
          const duration = item.countdown ?? exercisesMap[item.id]?.countdown;
          const thumbnailURL =
            item.thumbnail_URL || exercisesMap[item.id]?.thumbnail_URL;
          const exercise = exercisesMap[item.id];
          const title = item.title ?? exercise?.translations?.title;

          if (isRest) {
            return (
              <div
                key={index}
                className="p-4 bg-yellow-100 rounded-lg shadow-md flex items-center"
              >
                <div className="w-16 h-16 flex-shrink-0 flex items-center justify-center">
                  <FaClock className="text-yellow-600 text-3xl" />
                </div>
                <div className="ml-4">
                  <p className="text-xl font-semibold text-yellow-800">
                    {item.title || "Rest"}
                  </p>
                  <p className="text-gray-600">
                    Duration: {duration ? `${duration} seconds` : "N/A"}
                  </p>
                </div>
              </div>
            );
          }

          return (
            <div
              key={index}
              className="p-4 bg-white rounded-lg shadow-md flex items-center hover:bg-gray-50 transition duration-200"
            >
              <div className="w-16 h-16 flex-shrink-0">
                {thumbnailURL ? (
                  <img
                    src={thumbnailURL}
                    alt={title}
                    className="w-full h-full object-cover rounded"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 rounded" />
                )}
              </div>
              <div className="ml-4">
                {exercise ? (
                  <Link
                    to={`/exercises/${encodeURIComponent(exercise.id)}`}
                    className="text-xl font-semibold text-blue-600 hover:underline"
                  >
                    {title}
                  </Link>
                ) : (
                  <p className="text-xl font-semibold">{title}</p>
                )}
                <p className="text-gray-600">
                  {repeats !== undefined ? `Reps: ${repeats}` : ""}
                  {duration !== undefined
                    ? ` ${
                        repeats !== undefined ? "|" : ""
                      } Duration: ${duration} seconds`
                    : ""}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkoutDetails;
