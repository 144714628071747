import React from 'react';
import { Link } from 'react-router-dom';
import { PlanUpd } from './PlanUpd';

interface PlansGridProps {
  plans: PlanUpd[];
}

export const PlansGrid: React.FC<PlansGridProps> = ({ plans }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 sm:gap-4">
      {plans.map((plan) => (
        <Link
          key={plan.id}
          to={`/plans/${plan.id}`}
          className="block bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          <img
            loading="lazy"
            src={plan.img_URL}
            alt={plan.translations?.title}
            className="w-full h-24 sm:h-32 object-cover rounded-t-lg"
          />
          <div className="p-3">
            <h3 className="text-sm mb-2 font-semibold">{plan.translations?.title}</h3>
          </div>
        </Link>
      ))}
    </div>
  );
};
