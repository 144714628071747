import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchPlanById } from "../service/fetchPlans";
import { PlanUpd } from "../components/PlanUpd";

const PlanDetails = () => {
  const { planId } = useParams<{ planId: string }>();
  const [plan, setPlan] = useState<PlanUpd | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPlan = async () => {
      try {
        if (!planId) throw new Error("No plan ID provided");
        const planData = await fetchPlanById(planId);
        if (!planData) throw new Error("Plan not found");
        setPlan(planData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "Failed to load plan");
      } finally {
        setLoading(false);
      }
    };
    loadPlan();
  }, [planId]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );

  if (error)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );

  if (!plan)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-gray-500 text-xl">Plan not found</div>
      </div>
    );

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header */}
      <div className="flex flex-col md:flex-row gap-8 mb-8">
        <div className="md:w-1/3">
          <img
            src={plan.img_URL}
            alt={plan.translations?.title || ""}
            className="w-full rounded-lg shadow-lg"
          />
        </div>
        <div className="md:w-2/3">
          <h1 className="text-3xl font-bold mb-4">
            {plan.translations?.title}
          </h1>
          <p className="text-gray-600 mb-2">ID: {plan.id}</p>
          <div className="bg-gray-100 p-4 rounded-lg">
            <h2 className="text-xl font-semibold mb-2">Category</h2>
            <p>{plan.translations?.category.description}</p>
          </div>
        </div>
      </div>

      {/* Levels */}
      {Object.entries(plan.translations?.levels || {})
        .sort((a, b) => {
          const numA = parseInt(a[0].split("_")[1]);
          const numB = parseInt(b[0].split("_")[1]);
          return numA - numB;
        })
        .map(([levelKey, level]) => (
          <div key={levelKey} className="mb-8">
            <h3 className="text-2xl font-bold mb-4">{level.title}</h3>
            <div className="relative">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {Object.entries(level.days || {})
                  .sort(
                    ([aKey], [bKey]) => parseInt(aKey, 10) - parseInt(bKey, 10)
                  )
                  .map(([dayKey, day], index, array) => (
                    <div key={dayKey} className="relative">
                      {/* Connecting line */}
                      {index < array.length - 1 && (
                        <div
                          className="hidden md:block absolute top-1/2 -right-4 w-8 border-t-4 border-dotted border-gray-500"
                          style={{
                            transform: "translateY(-50%)",
                            zIndex: 0,
                          }}
                        />
                        
                      )}
                      {/* Card content */}
                      <div className={`relative border rounded-lg p-4 shadow-sm ${day.title.includes("Rest") ? "bg-yellow-100" : "bg-white"} hover:shadow-md transition-shadow z-10`}>
                        <h4 className={`text-base mb-2 ${day.title.includes("Rest") ? "text-center mt-3" : "text-start"}`}>
                          {day.title.includes("Rest") ? "Rest Day" : day.title}
                        </h4>
                        {day.workouts && day.workouts[0] && (
                          <Link
                          key={day.workouts[0].id}
                          to={`/workouts/${encodeURIComponent(day.workouts[0].id)}`}
                          className="text-blue-500 font-bold hover:underline block"
                        >
                          {day.workouts[0].title}
                        </Link>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default PlanDetails;
