import { db } from '../components/firebase';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { enPlanTranslation, PlanUpd } from '../components/PlanUpd';

// Add memory cache
let plansCache: PlanUpd[] | null = null;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
let lastFetchTime = 0;

export const fetchPlansUpd = async (): Promise<PlanUpd[]> => {
  // Return cached data if available and not expired
  if (plansCache && (Date.now() - lastFetchTime) < CACHE_DURATION) {
    return plansCache;
  }

  const plansUpdCollection = collection(db, 'plans_upd');
  const querySnapshot = await getDocs(plansUpdCollection);
  
  // Use Promise.all to fetch all plans and their translations concurrently
  const plansUpdData = await Promise.all(querySnapshot.docs.map(async (planDoc) => {
    const planData = planDoc.data();
    
    // Get reference to translations collection for this plan
    const translationsRef = doc(planDoc.ref, 'translations', 'en');
    const translationsSnap = await getDoc(translationsRef);
    
    const translations = translationsSnap.exists() ? 
      translationsSnap.data() as enPlanTranslation : 
      null;

    return {
      id: planDoc.id,
      img_URL: planData.img_URL,
      filter_fields: planData.filter_fields,
      created_by: planData.created_by,
      created_date: planData.created_date,
      translations: translations
    } as PlanUpd;
  }));

  // Update cache
  plansCache = plansUpdData;
  lastFetchTime = Date.now();
  return plansUpdData;
};

export const fetchPlanById = async (planId: string): Promise<PlanUpd | null> => {
  try {
    // Get plan document reference
    const planRef = doc(db, 'plans_upd', planId);
    const planSnap = await getDoc(planRef);

    if (!planSnap.exists()) {
      return null;
    }

    const planData = planSnap.data();

    // Get translations document
    const translationsRef = doc(planRef, 'translations', 'en');
    const translationsSnap = await getDoc(translationsRef);
    
    const translations = translationsSnap.exists() ? 
      translationsSnap.data() as enPlanTranslation : 
      null;

    return {
      id: planSnap.id,
      img_URL: planData.img_URL,
      filter_fields: planData.filter_fields,
      created_by: planData.created_by, 
      created_date: planData.created_date,
      translations: translations
    } as PlanUpd;

  } catch (error) {
    console.error('Error fetching plan:', error);
    return null;
  }
};